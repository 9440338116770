import React from 'react'
import SignIn from '../components/auth/signin'
//Need Amplify configuration here in case user navigate here directly.
import Amplify from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)

const PageSignIn = () => (
  <SignIn/>
)

export default PageSignIn