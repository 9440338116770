/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      patients {
        items {
          id
          userID
          memberID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      title
      firstname
      lastname
      dob
      gender
      bpPatientId
      users {
        items {
          id
          userID
          memberID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        firstname
        lastname
        dob
        gender
        bpPatientId
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      createdAt
      updatedAt
      bookedBy
      canceledBy
      patientID
      appointmentDate
      appointmentTime
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        bookedBy
        canceledBy
        patientID
        appointmentDate
        appointmentTime
      }
      nextToken
    }
  }
`;
export const appointmentsByTime = /* GraphQL */ `
  query AppointmentsByTime(
    $appointmentDate: String
    $appointmentTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByTime(
      appointmentDate: $appointmentDate
      appointmentTime: $appointmentTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        bookedBy
        canceledBy
        patientID
        appointmentDate
        appointmentTime
      }
      nextToken
    }
  }
`;
export const getComm = /* GraphQL */ `
  query GetComm($id: ID!) {
    getComm(id: $id) {
      id
      name
      phone
      email
      message
      preferredMethod
      isFeedback
      forAppointment
      reply
      resolved
      createdAt
      updatedAt
    }
  }
`;
export const listComms = /* GraphQL */ `
  query ListComms(
    $filter: ModelCommFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        email
        message
        preferredMethod
        isFeedback
        forAppointment
        reply
        resolved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByResolved = /* GraphQL */ `
  query MessagesByResolved(
    $resolved: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByResolved(
      resolved: $resolved
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phone
        email
        message
        preferredMethod
        isFeedback
        forAppointment
        reply
        resolved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
